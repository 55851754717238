import React from "react";
import { Link, useLocation } from "react-router-dom";

export default function Nav() {
  const location = useLocation();

  return (
    <nav className="text-left">
      <Link to="/">
        <h1 className="text-3xl">Lucas Lebrun</h1>
      </Link>
      <h2 className="font-light">Fullstack Java Developer</h2>
      <ul className="font-semibold">
        <li className={location.pathname === "/" ? "selected" : ""}>
          <Link to="/">Home</Link>
        </li>
        <li className={location.pathname === "/projects" ? "selected" : ""}>
          <Link className="inline" to="/projects">
            Projects
          </Link>
        </li>
        <li className={location.pathname === "/about" ? "selected" : ""}>
          <Link to="/about">About</Link>
        </li>
      </ul>
    </nav>
  );
}
