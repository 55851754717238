import React, { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { projects } from "../content/projects";

export default function Projects() {
  useEffect(() => {
    AOS.init();
  }, []);

  const [selected, setSelected] = useState(0);

  const selectProject = (index) => {
    if (window.innerWidth < 601) {
      setSelected(index);
      return;
    }

    const section = document.getElementById("projects");
    const projectElement = section.getElementsByClassName("project")[index];

    if (projectElement) {
      const offsetTop = projectElement.offsetTop - 145;
      section.scrollTo({
        top: offsetTop,
        behavior: "smooth",
      });
    }

    setTimeout(() => {
      setSelected(index);
    }, 250);
  };

  useEffect(() => {
    const section = document.getElementById("projects");
    const projects = section.getElementsByClassName("project");
    let offsetFirstElement = projects[0].offsetTop;
    console.log(offsetFirstElement);
    let PreviousSecondElementIndex = 0;
    let SecondElementIndex = 0;

    const handleScroll = () => {
      const scrollPosition = section.scrollTop;
      for (let i = 0; i < projects.length; i++) {
        const offsetTop = projects[i].offsetTop;
        if (offsetTop >= scrollPosition + offsetFirstElement) {
          console.log(i);
          SecondElementIndex = i;
          break;
        }
      }

      if (SecondElementIndex === PreviousSecondElementIndex) {
        return;
      }

      setTimeout(() => {
        setSelected(SecondElementIndex);
        PreviousSecondElementIndex = SecondElementIndex;
      }, 700);
    };

    const handleResize = () => {
      offsetFirstElement = projects[0].offsetTop;
      if (window.innerWidth < 601) {
        section.removeEventListener("scroll", handleScroll);
      } else {
        section.addEventListener("scroll", handleScroll);
      }
    };

    section.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      section.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section
        id="projects-images"
        data-aos="fade-in"
        data-aos-duration="1000"
        className=" "
      >
        {projects.map((project, index) => (
          <div
            key={index}
            className={`project-extra transition-all   duration-500 ${
              index === selected ? "opacity-100 " : "opacity-0	"
            }`}
          >
            <a
              target="_blank"
              href={project.projectLink}
              className={`${index === selected ? "block" : "hidden	"}`}
            >
              <div
                className="img-container hidden md:block "
                style={{
                  backgroundImage: `url(${
                    process.env.PUBLIC_URL + project.img
                  })`,
                }}
              ></div>
            </a>
            <div
              className="img-container-phone block md:hidden "
              style={{
                backgroundImage: `url(${
                  process.env.PUBLIC_URL + project.imgPhone
                })`,
              }}
            ></div>
          </div>
        ))}
      </section>
      <section
        id="projects"
        data-aos="fade-in"
        data-aos-duration="1000"
        className="gradient-opacity-bottom snap-y scroll-p-4"
      >
        <div className="empty snap-start hidden sm:block pt-[55px] pb-[55px] text-3xl w-fit ml-auto ">
          Projects{" "}
        </div>
        {projects.map((project, index) => (
          <div
            key={index}
            onClick={() => selectProject(index)}
            className={`project transition-all duration-300 mb-8 flex flex-col items-end snap-start	${
              index === selected ? " font-light" : " secondary-color"
            }`}
          >
            <h2
              className={` -mb-[5px]		 ${
                index === selected
                  ? "text-4xl sm:text-6xl lg:text-8xl font-normal"
                  : "text-3xl sm:text-4xl lg:text-5xl secondary-color font-light"
              }`}
            >
              <div className="background"></div>
              <div className="title relative background text-right w-fit">
                {project.title}
                <a
                  target="_blank"
                  href={project.projectLink}
                  className={`absolute w-full h-full top-0 left-0 ${
                    index === selected ? "block" : "hidden	"
                  }`}
                ></a>
              </div>
            </h2>
            <p className="text-lg background text-end max-w-[80%] sm:max-w-full">
              {project.description}
            </p>
            <div className="flex justify-end gap-[1px] flex-wrap">
              {project.badges.map((badge, indexBadge) => (
                <span
                  key={indexBadge}
                  className={`border px-[4px] rounded background	${
                    index === selected ? "badge-selected" : " badge"
                  }`}
                >
                  {badge}
                </span>
              ))}
            </div>
            {project.projectLink && (
              <a
                href={project.projectLink}
                target="_blank"
                rel="noopener noreferrer"
                className=" background underline-offset-2 text-lg flex gap-1 justify-end items-center h-min"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 24 24"
                >
                  <path d="M12.068.016l-3.717 3.698 5.263 5.286h-13.614v6h13.614l-5.295 5.317 3.718 3.699 11.963-12.016z" />
                </svg>
                See in action<br></br>
              </a>
            )}

            {project.sourceCodeLink && (
              <a
                href={project.sourceCodeLink}
                target="_blank"
                rel="noopener noreferrer"
                className=" background underline-offset-2 flex gap-1 justify-end items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  viewBox="0 0 24 24"
                >
                  <path d="M12.068.016l-3.717 3.698 5.263 5.286h-13.614v6h13.614l-5.295 5.317 3.718 3.699 11.963-12.016z" />
                </svg>
                Source code
              </a>
            )}
          </div>
        ))}
        <div className="empty h-[1000px] snap-start"></div>
      </section>
    </>
  );
}
