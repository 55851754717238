export const projects = [
  {
    title: "NewSchool",
    description: "Learning-Management-System, Messaging, Forum, Webshop",
    badges: ["JPA", "Spring", "Junit"],
    img: "/images/NewSchool.png",
    imgPhone: "/images/NewSchool-phone.png",
    projectLink: "/Java_Enterprise_-_NewSchool_LMS.pdf",
    sourceCodeLink: "https://gitlab.com/lucaslebrun.dev/newschool",
  },
  {
    title: "Legend of the Lambs",
    description: "Text-based RPG Game",
    badges: ["Java", "I/O", "Streams"],
    img: "/images/LOTL.png",
    imgPhone: "/images/LOTL-phone.png",
    sourceCodeLink: "https://gitlab.com/lucaslebrun.dev/the-legend-of-the-lamb",
    projectLink:
      "/Java_Gevorderd_-_Project_2_-_Text-based_RPG_Game_-_Opgave.pdf",
  },
  {
    title: "MVC Films",
    description: "CRUD App with Role-based Authorization",
    badges: ["Thymeleaf", "Spring Security", "JPA"],
    img: "/images/mvc-films.png",
    imgPhone: "/images/mvc-films-phone.png",
    sourceCodeLink: "https://gitlab.com/lucaslebrun.dev/mcv-films",
  },
  {
    title: "Pokedex",
    description: "search, filter and sort pokemons",
    badges: ["Api", "Cookies", "Accessibility"],
    img: "/images/pokedex.png",
    imgPhone: "/images/pokedex-phone.png",
    projectLink: "https://lucaslebrun-dev-pokedex.netlify.app/",
    sourceCodeLink: "https://gitlab.com/lucaslebrun.dev/pokedex",
  },
  {
    title: "Pizzeria",
    description: "Angular/TailwindCSS webshop",
    badges: ["Angular", "TailwindCSS"],
    img: "/images/pizzeria.png",
    imgPhone: "/images/pizzeria-phone.png",
    projectLink: "https://lucaslebrun-pizzeria.netlify.app",
    sourceCodeLink: "https://gitlab.com/lucaslebrun.dev/pizzeria-angular",
  },
  {
    title: "The Planets",
    description: "Angular/Bootstrap website",
    badges: ["Angular", "Bootstrap", "AOS"],
    img: "/images/the-planets.png",
    imgPhone: "/images/the-planets-phone.png",
    projectLink: "https://lucaslebrun-the-planets.netlify.app",
    sourceCodeLink: "https://gitlab.com/lucaslebrun.dev/the-planets",
  },
  {
    title: "Murielle LEMAY",
    description: "Portolfio website",
    badges: ["Design", "React", "TailwindCSS", "TinaCMS"],
    img: "/images/murielle-lemay.jpg",
    imgPhone: "/images/murielle-lemay-phone.png",
    projectLink: "https://www.muriellelemay.com/",
  },
  {
    title: "Weather App",
    description: "Location based weather App",
    badges: ["Api", "Javascript"],
    img: "/images/weather-app.png",
    imgPhone: "/images/weather-app-phone.png",
    projectLink: "https://local-weather-forecast.netlify.app/",
    sourceCodeLink: "https://gitlab.com/lucaslebrun.dev/weather-app",
  },
  {
    title: "De CENTRALE",
    description: "Audiovisual installation",
    badges: ["C++", "Design", "Laser"],
    img: "/images/de-centrale.jpg",
    imgPhone: "/images/de-centrale-phone.jpg",
    projectLink:
      "https://www.youtube.com/watch?v=CCTYEY540IU&ab_channel=LucasLEBRUN",
  },
];
