import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PostHog } from "posthog-node";

const client = new PostHog("phc_6NlNRzrU8oVycmGsryZTFB18Erb2j3CRq6evAyodzsA", {
  host: "https://us.i.posthog.com",
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

client.capture({
  distinctId: "test-id",
  event: "test-event",
});

// Send queued events immediately. Use for example in a serverless environment
// where the program may terminate before everything is sent.
// Use `client.flush()` instead if you still need to send more events or fetch feature flags.
client.shutdown();
