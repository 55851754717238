export const SKILLS = [
  {
    category: "Backend",
    skills: [
      "Java",
      "Maven",
      "Spring Boot",
      "Spring Security",
      "Hibernate",
      "MySQL",
      "PostgreSQL",
      "JUnit",
      "Javascript",
      "Typescript",
      "Rest APIs",
    ],
  },
  {
    category: "Frontend",
    skills: [
      "HTML, CSS",
      "Angular",
      "React",
      "TailwindCSS",
      "Chart.js",
      "P5.js",
      "Bootstrap",
      "Responsive Design",
      "Figma",
    ],
  },
  {
    category: "Tools",
    skills: [
      "Git",
      "GitHub",
      "Gitlab",
      "Postman",
      "IntelliJ IDEA",
      "VS Code",
      "Docker",
      "Netlify",
      "Render",
      "Aiven",
      "Adobe Creative Suite",
      "Jira",
    ],
  },
  {
    category: "Languages",
    skills: ["Dutch", "French", "English"],
  },
  {
    category: "Methodologies",
    skills: ["Agile", "Scrum", "Pair Programming", "Test Driven Development"],
  },
  {
    category: "Other",
    skills: ["Signal Processing", "Sound Design"],
  },
];
