import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { intro } from "../content/intro";

function Intro({ language }) {
  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <section
      className="text-right font-normal"
      id="intro"
      data-aos="fade-in"
      data-aos-duration="1000"
    >
      {language === "EN" && (
        <div data-aos="fade-in" data-aos-duration="1000">
          {intro["EN"]}
        </div>
      )}
      {language === "FR" && (
        <div data-aos="fade-in" data-aos-duration="1000">
          {intro["FR"]}
        </div>
      )}
      {language === "NL" && (
        <div data-aos="fade-in" data-aos-duration="1000">
          {intro["NL"]}
        </div>
      )}
    </section>
  );
}

export default Intro;
