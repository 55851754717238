export const intro = {
  EN: (
    <p>
      Born in 1994 in Nantes, France <br />
      Living in Leuven, Belgium <br />
      I believe that programming <br />
      can be a form of art <br />I am currently open to work
    </p>
  ),
  FR: (
    <p>
      Né en 1994 à Nantes, France <br />
      Résidant à Louvain, Belgique <br />
      Je crois que la programmation <br />
      peut être une forme d'art <br />
      Je suis ouvert à de nouvelles <br />
      propositions d'emplois
    </p>
  ),
  NL: (
    <p>
      Geboren in 1994 in Nantes, Frankrijk <br />
      Wonen in Leuven, België <br />
      Ik geloof dat ontwikkeling <br /> een vorm van kunst kan zijn
      <br /> Ik ben nu open voor nieuwe <br /> professionele aanbiedingen
    </p>
  ),
};
