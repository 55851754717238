export default function Language({ onLanguageChange, language }) {
  return (
    <section id="language" className="text-sm font-extralight">
      <a
        onClick={() => onLanguageChange("EN")}
        className={language === "EN" ? "font-bold" : ""}
      >
        EN
      </a>

      <a
        onClick={() => onLanguageChange("NL")}
        className={language === "NL" ? "font-bold" : ""}
      >
        NL
      </a>
      <a
        onClick={() => onLanguageChange("FR")}
        className={language === "FR" ? "font-bold" : ""}
      >
        FR
      </a>
    </section>
  );
}
