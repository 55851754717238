import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import { SKILLS } from "../content/skills";
import { background } from "../content/about-background";

function About({ language }) {
  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    document.getElementById("skills").classList.add("fadein");
  }, []);

  return (
    <section id="about" className="lg:flex lg:gap-8 text-left font-light ">
      <section id="background" className="lg:w-1/2 lg:gradient-opacity ">
        <div
          id="background-container"
          className="lg:gradient-opacity-bottom h-full"
        >
          {language === "EN" && (
            <div data-aos="fade-in" data-aos-duration="1000">
              {background["EN"]}
            </div>
          )}
          {language === "FR" && (
            <div data-aos="fade-in" data-aos-duration="1000">
              {background["FR"]}
            </div>
          )}
          {language === "NL" && (
            <div data-aos="fade-in" data-aos-duration="1000">
              {background["NL"]}
            </div>
          )}
        </div>
      </section>
      <section id="skills" className="lg:w-1/2 text-lg md:text-xl">
        <h2 className="mb-4 text-3xl">Skills</h2>
        <div
          id="skills-container"
          className="flex flex-wrap text-left gap-y-4 lg:gradient-opacity-bottom ml-[-10px] pl-[10px]"
        >
          {SKILLS.map((category) => (
            <div key={category.category} className="w-1/2 sm:w-1/3 ">
              <h3 className="mb-2 underline underline-offset-4">
                {category.category}
              </h3>
              <ul>
                {category.skills.map((skill) => (
                  <li key={skill}>{skill}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </section>
    </section>
  );
}

export default About;
