import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Nav from "./components/Nav";
import Intro from "./components/Intro";
import Language from "./components/Language";
import Credits from "./components/Credits";
import Theme from "./components/Theme";
import Projects from "./components/Projects";
import About from "./components/About";
import Welcome from "./components/Welcome";
import { projects } from "./content/projects";

function App() {
  const [language, setLanguage] = useState("EN");

  useEffect(() => {
    preloadImages();
  }, []);

  const preloadImages = () => {
    projects.forEach((project) => {
      const img = new Image();
      img.src = process.env.PUBLIC_URL + project.img;
      const imgPhone = new Image();
      imgPhone.src = process.env.PUBLIC_URL + project.imgPhone;
    });
  };

  return (
    <Router>
      <div className="App ">
        <Language onLanguageChange={setLanguage} language={language} />
        <div id="frame" className="text-sm font-thin select-none">
          <Nav />
          <Routes>
            <Route path="/" element={<Intro language={language} />} />
            <Route path="/projects" element={<Projects />} />
            <Route path="/about" element={<About language={language} />} />
          </Routes>
        </div>
        <Theme />
        <Credits />
      </div>
      <Welcome />
    </Router>
  );
}

export default App;
